import React, { useEffect, useMemo, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';
import { isFuture } from 'date-fns';
import { format } from 'date-fns/format';

import {
  CancelSubscribeModal,
  ECancelSubscribeStep,
  useDeleteSubscriptionMutation,
  useSubscribeModal,
} from '@/features/billing';
import { ConfirmModal } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import { useMeQuery } from '@/features/me';
import { useHistory, useWorkspaceId } from '@/hooks/router';

import clock from './clock.png';

import './PauseSubscriptionOverlay.css';

const PauseSubscriptionOverlay = () => {
  const workspaceId = useWorkspaceId();

  const [resumeModalState, setResumeModalState] = useState<boolean>(false);
  const [cancelSubscribeStep, setCancelSubscribeStep] = useState<
    ECancelSubscribeStep | undefined
  >();

  const meQuery = useMeQuery();

  const { subscriptionPausedUntil, subscriptionPausedFrom } = useMemo(() => {
    if (!meQuery.isSuccess) {
      return {
        subscriptionPausedUntil: null,
        subscriptionPausedFrom: null,
      };
    }

    return {
      subscriptionPausedUntil: meQuery.data.workspaces?.find(
        workspace => workspace.id === workspaceId
      )?.subscriptionPausedUntil,
      subscriptionPausedFrom: meQuery.data.workspaces?.find(
        workspace => workspace.id === workspaceId
      )?.subscriptionPausedFrom,
    };
  }, [workspaceId, meQuery.isSuccess, meQuery.data?.workspaces]);

  const subscriptionPausedInFuture =
    subscriptionPausedFrom && isFuture(subscriptionPausedFrom);
  const isPaused =
    subscriptionPausedUntil &&
    !subscriptionPausedInFuture &&
    isFuture(subscriptionPausedUntil);

  useEffect(() => {
    if (isPaused) {
      document.body.classList.add('pause-subscription-overlay--no-scroll');
    } else {
      document.body.classList.remove('pause-subscription-overlay--no-scroll');
    }

    return () =>
      document.body.classList.remove('pause-subscription-overlay--no-scroll');
  }, [isPaused]);

  return (
    <>
      {isPaused && (
        <>
          <div className="pause-subscription-overlay__container">
            <div className="pause-subscription-overlay__content">
              <img
                src={clock}
                alt=""
                width="127.55"
                height="150.43"
                className="pause-subscription-overlay__graphic"
              />
              <div className="pause-subscription-overlay__message">
                <h1 className="pause-subscription-overlay__title">
                  Hello {meQuery.data?.name}!<br />
                  Your subscription is on pause.
                </h1>
                <p className="pause-subscription-overlay__description">
                  Your subscription is set to automatically restart on{' '}
                  {subscriptionPausedUntil &&
                    format(subscriptionPausedUntil, 'MMM d, yyyy')}
                  .
                  <br />
                  Resume your subscription now to get immediate access to your
                  account.
                </p>
                <ActionButtons>
                  <DSButton
                    variant="secondary"
                    onClick={() =>
                      setCancelSubscribeStep(ECancelSubscribeStep.ConfirmCancel)
                    }
                  >
                    Cancel subscription
                  </DSButton>
                  <DSButton onClick={() => setResumeModalState(true)}>
                    Resume subscription
                  </DSButton>
                </ActionButtons>
              </div>
            </div>
          </div>
          <ResumeModal
            resumeModalState={resumeModalState}
            setResumeModalState={setResumeModalState}
          />
          <CancelSubscribeModal
            currentStep={cancelSubscribeStep}
            setCurrentStep={setCancelSubscribeStep}
          />
        </>
      )}
    </>
  );
};

const ResumeModal = ({
  resumeModalState,
  setResumeModalState,
}: {
  resumeModalState: boolean;
  setResumeModalState: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const workspaceId = useWorkspaceId();
  const { openSubscribeModal } = useSubscribeModal();
  const history = useHistory();
  const meQuery = useMeQuery();

  const deleteSubscriptionMutation = useDeleteSubscriptionMutation({
    onSuccess: () => {
      currentPlan && openSubscribeModal(currentPlan);
      amplitude?.logEvent(`billing/subscription resumed`, {
        'workspace id': workspaceId,
      });
      showFlashMessage({ type: 'saved' });
      history.push(`/admin/${workspaceId}/settings/billing`);
    },
  });
  const { showFlashMessage } = useFlashMessages();

  const currentPlan = useMemo(() => {
    return (
      meQuery.data?.workspaces?.find(workspace => workspace.id === workspaceId)
        ?.subscriptionPlan ?? null
    );
  }, [workspaceId, meQuery.data?.workspaces]);

  return (
    <ConfirmModal
      open={resumeModalState}
      action="Confirm"
      onConfirm={() => {
        setResumeModalState(false);
        deleteSubscriptionMutation.mutate();
      }}
      onClose={() => setResumeModalState(false)}
      confirmation="Resume"
    >
      <h3>Are you sure?</h3>
      <p>You are about to resume your subscription. Do you want to proceed?</p>
    </ConfirmModal>
  );
};

const ActionButtons = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 12px;
  margin: 18px 0;
`;

export default PauseSubscriptionOverlay;
