import { useCallback } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { CANDIDATE_EMAILS_QUERY_KEY } from '@/features/candidate';
import { useFlashMessages } from '@/features/flash-messages';

import {
  deleteGmailData,
  deleteGmailIntegration,
  enableGmail,
  enableGmailCallback,
  fetchMe,
  updateMe,
} from '../api';
import { isUserInSupportMode } from '../utils/me';

export const ME_QUERY_KEY = ['me'];

export const useMeQuery = (options?: { enabled?: boolean }) =>
  useQuery(ME_QUERY_KEY, fetchMe, {
    enabled: options?.enabled ?? true,
    onSuccess: async me => {
      // update timezone if none is set
      if (me.timezone === null && !isUserInSupportMode(me)) {
        const region = new Intl.DateTimeFormat();
        const options = region.resolvedOptions();

        if (options.timeZone) {
          await updateMe({ ...me, timezone: options.timeZone });
        }
      }

      // Update amplitude data
      const identifyEvent = new amplitude.Identify();

      identifyEvent.set('support', me.id === '0');
      identifyEvent.set('demo', /^demo\+.+@hire.toggl\.com$/.test(me.email));

      amplitude.setUserId(me.id);
      amplitude.identify(identifyEvent);
    },
  });

export const useMeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(updateMe, {
    onSuccess: () => queryClient.invalidateQueries(ME_QUERY_KEY),
  });
};

export const usePrefetchMe = () => {
  const queryClient = useQueryClient();
  return useCallback(async () => {
    await queryClient.prefetchQuery(ME_QUERY_KEY, fetchMe);
  }, [queryClient]);
};

export const useEnableGmailMutation = (options?: UseMutationOptions) => {
  const { showFlashMessage } = useFlashMessages();

  return useMutation(() => enableGmail(), {
    mutationKey: ME_QUERY_KEY,
    onSuccess: () => {
      showFlashMessage({
        type: 'saved',
      });
    },
    ...(options as any),
  });
};

export const useEnableGmailCallbackMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const { showFlashMessage } = useFlashMessages();

  return useMutation((query: string) => enableGmailCallback({ query }), {
    mutationKey: ME_QUERY_KEY,
    onSuccess: () => {
      showFlashMessage({
        type: 'saved',
      });
      queryClient.invalidateQueries(ME_QUERY_KEY);
    },
    ...(options as any),
  });
};

export const useDeleteGmailIntegrationMutation = (
  params?: {
    onSuccess?: () => void;
  },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const { showFlashMessage } = useFlashMessages();

  return useMutation(deleteGmailIntegration, {
    onSuccess: () => {
      queryClient.invalidateQueries(ME_QUERY_KEY);
      showFlashMessage({
        type: 'saved',
      });
      params?.onSuccess?.();
    },
    ...(options as any),
  });
};

export const useDeleteGmailDataMutation = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();
  const { showFlashMessage } = useFlashMessages();

  return useMutation(deleteGmailData, {
    onSuccess: () => {
      queryClient.invalidateQueries(ME_QUERY_KEY);
      queryClient.invalidateQueries(CANDIDATE_EMAILS_QUERY_KEY);

      showFlashMessage({
        type: 'gmail_user_data_deleted',
      });
    },
    ...(options as any),
  });
};
