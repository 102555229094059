import { useEffect, RefObject } from 'react';

const EVENTS = [`click`, `touchstart`];

export function useClickOutside(
  ref: RefObject<HTMLElement>,
  handler: (event: Event) => void
) {
  useEffect(() => {
    () => {
      if (!ref.current || !handler) {
        return;
      }

      const listener = (event: Event) => {
        if (ref.current?.contains(event.target as Node)) {
          return;
        }

        handler(event);
      };

      EVENTS.forEach(event => {
        document.addEventListener(event, listener);
      });

      return () => {
        EVENTS.forEach(event => {
          document.removeEventListener(event, listener);
        });
      };
    };
  }, [handler, ref]);
}
