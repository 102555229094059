import React from 'react';
import styled from '@emotion/styled';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton, DSModal, LinkButton } from '@hundred5/design-system';
import { useHistory } from 'react-router';

import UpgradeBanner from '@/components/upgrade_banner/UpgradeBanner';
import { BillingPlan } from '@/features/billing';
import { Icon } from '@/features/common';
import { getPlanName } from '@/hooks/planLimits';
import { useWorkspaceId } from '@/hooks/router';

import { usePreviewFeatureModal } from '../hooks';
import { PreviewFeatureModalType } from '../types';
import {
  previewIcons,
  pros,
  subTitles,
  titles,
  upsells,
  useRequiredPlans,
} from '../utils';

import { DiamondIcon } from './icons';

export function PreviewFeatureModal() {
  const { isOpen, close, type } = usePreviewFeatureModal();
  const requiredPlans = useRequiredPlans();
  const history = useHistory();
  const workspaceId = useWorkspaceId();
  const createCustomTest = () => {
    history.push(`/admin/${workspaceId}/test-library`);
  };

  if (!type) {
    return null;
  }

  const isCustomTest = type === 'custom_test';

  const previewIcon = previewIcons[type];
  return (
    <DSModal
      fluid
      open={isOpen}
      onClose={close}
      layer={2}
      contentStyle={{ padding: 0 }}
    >
      <Container data-testid="preview-feature-modal">
        <DSModal.CloseButton onClick={close} small fixed />

        <Title isCustomTest={isCustomTest}>{titles[type]}</Title>
        <OrderWrapper isCustomTest={isCustomTest}>
          <Wrapper>
            <SubTittle>{subTitles[type]}</SubTittle>
            <ProsList>
              {pros[type].map(text => (
                <Pros key={text}>{text}</Pros>
              ))}
            </ProsList>
          </Wrapper>
          <UpgradeBanner
            icon={<DiamondIcon />}
            title={getUpsellTitle(requiredPlans[type])}
            caption={upsells[type]}
            requiredPlan={requiredPlans[type]}
            upgradeButtonVisible={!isCustomTest}
            onUpgrade={close}
          />
        </OrderWrapper>

        {isCustomTest ? (
          <ButtonWrapper>
            <LinkButtonWrapper>
              <LinkButton variant="secondary" onClick={close}>
                Cancel
              </LinkButton>
            </LinkButtonWrapper>
            <DSButton onClick={createCustomTest}>Create test</DSButton>
          </ButtonWrapper>
        ) : (
          <Wrapper>
            <SubTittle>Preview of the feature</SubTittle>
            <PreviewIconWrapper>
              <PreviewImage modalType={type} src={previewIcon} />
            </PreviewIconWrapper>
          </Wrapper>
        )}
      </Container>
    </DSModal>
  );
}

const Pros = ({ children }) => (
  <ProsWrapper>
    <Icon icon={solid('circle-check')} size="2xl" color="orange-100" />
    <div>{children}</div>
  </ProsWrapper>
);

const getUpsellTitle = (plan: BillingPlan) => {
  const planName = getPlanName(plan);
  return `Great choice! You discovered a ${planName[0].toUpperCase() +
    planName.slice(1)} Plan feature.`;
};

const Container = styled.div`
  @media all and (min-width: 769px) and (max-width: 992px) {
    width: 700px;
  }

  @media all and (min-width: 992px) {
    width: 848px;
  }
`;
const Wrapper = styled.div`
  padding: 36px;
  overflow: hidden;
`;
const Title = styled.div<{ isCustomTest: boolean }>`
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  color: ${props => props.theme.colors.purple[100]};
  padding-top: 36px;
  padding-left: 36px;
  margin-bottom: ${props => (props.isCustomTest ? '12px' : '-12px')};
`;

const SubTittle = styled.div`
  font-weight: 700;
  font-size: 15.9px;
  line-height: 19px;
  margin-bottom: 36px;
`;

const ProsList = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 25px;
`;

const ProsWrapper = styled.div`
  flex-direction: column;
  display: flex;
  max-width: 235px;
  justify-content: flex-start;
  align-items: center;
  gap: 17px;
  text-align: center;
`;

const PreviewIconWrapper = styled.div`
  svg {
    @media all and (max-width: 992px) {
      width: 100%;
      height: auto;
    }
  }
`;

const PreviewImage = styled.img<{ modalType: PreviewFeatureModalType }>`
  width: ${props =>
    props.modalType === 'redirect_url'
      ? 'calc(100% + 72px)'
      : 'calc(100% + 16px)'};
  margin: 0 ${props => (props.modalType === 'redirect_url' ? '-36px' : '-8px')};
`;

const OrderWrapper = styled.div<{ isCustomTest: boolean }>`
  display: flex;
  flex-direction: ${props =>
    props.isCustomTest ? 'column-reverse' : 'column'};
`;

const ButtonWrapper = styled.div`
  padding: 36px;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
`;

const LinkButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;
`;
