import React, { useCallback, useMemo, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { DSButton, DSInlineField, DSRadio } from '@hundred5/design-system';
import { debounce } from 'lodash';
import { useParams } from 'react-router';

import { PlanTag, TId } from '@/features/common';
import { useWorkspacePermission } from '@/features/permissions';
import { useJobOpeningTestsLimitQuery } from '@/features/plan-limits';
import { usePreviewFeatureModal } from '@/features/preview-feature/hooks';
import {
  DEFAULT_CATEGORY_TAB,
  ISkill,
  Skill,
  SkillGroup,
  SkillTabs,
  TAB_LABEL_DICTIONARY,
  useSkillCategoryTabs,
  useSkillsQuery,
} from '@/features/skills';
import { TestType, useCreateTestMutation } from '@/features/test';
import { usePlanLimits } from '@/hooks/planLimits';
import {
  useHistory,
  useJobOpeningIdOrNull,
  useWorkspaceId,
} from '@/hooks/router';
import { useUpsellModal } from '@/hooks/upsellModal';
import { trackIntercomEvent } from '@/intercom';

import { useAddTestToPipelineModalContext, useTestOnly } from '../../hooks';
import { EmptyState } from '../empty-state';
import { SearchBar } from '../test-library/ui';

export function VideoIntroLibrary() {
  const canAccessFeature = usePlanLimits();
  const canCreateCustomTest = canAccessFeature('create_custom_test');
  const canAccessAdvancedSkills = canAccessFeature('hard_difficulty');
  const showPreviewFeatureModal = usePreviewFeatureModal();
  const { openUpsellModal } = useUpsellModal();
  const history = useHistory();
  const testOnly = useTestOnly();
  const canCreateJobOpening = useWorkspacePermission()(
    'job openings',
    'create'
  );
  const {
    open: openAddTestToPipelineModal,
  } = useAddTestToPipelineModalContext();

  const workspaceId = useWorkspaceId();
  const jobOpeningId = useJobOpeningIdOrNull();
  const { categoryId } = useParams<{
    categoryId: TId;
  }>();

  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState(DEFAULT_CATEGORY_TAB);
  const [selectedSkills, setSelectedSkills] = useState<ISkill[]>([]);
  const [selectedDifficulty, setSelectedDifficulty] = useState<
    'standard' | 'hard'
  >('standard');

  const skillsQuery = useSkillsQuery({
    requestParams: { testType: TestType.Video },
  });
  const { isTestsInJobOpeningLimitReached } = useJobOpeningTestsLimitQuery();
  const createTestMutation = useCreateTestMutation();

  const {
    allTabs,
    allTabsKeys,
    filteredTabs,
    filteredTabsKeys,
  } = useSkillCategoryTabs({
    skills: skillsQuery?.data ?? [],
    searchQuery,
  });

  const trackQueriedSkills = useCallback(
    (searchQuery: string) =>
      debounce(() => {
        const trimmedSearchQuery = searchQuery.trim();

        if (trimmedSearchQuery) {
          trackIntercomEvent('skill_searched', {
            skill: trimmedSearchQuery,
          });
        }
      }, 1500),
    []
  );

  const handleSearchChange = useCallback(
    event => {
      const { value } = event.target;
      setSearchQuery(value);
      setActiveTab(value === '' ? 'Tech' : '');
      trackQueriedSkills(value);
    },
    [trackQueriedSkills]
  );

  const handleSkillSelection = useCallback(
    (selectedSkill: ISkill) => {
      const alreadySelected = selectedSkills.some(
        currentlySelectedSkill => currentlySelectedSkill.id === selectedSkill.id
      );

      if (alreadySelected) {
        setSelectedSkills(prevSkills =>
          prevSkills.filter(skill => skill.id !== selectedSkill.id)
        );
        return;
      }

      setSelectedSkills([selectedSkill]);
    },
    [selectedSkills]
  );

  const createTest = useCallback(
    async ({ jobOpeningId, categoryId, custom }) => {
      const createdTest = await createTestMutation.mutateAsync({
        jobOpeningId,
        attributes: {
          type: TestType.Video,
          name: custom
            ? 'Custom test: Video Intro'
            : `${selectedSkills[0].name.replace('VQ', '').trim()}: Video Intro`,
          durationInSeconds: 0,
          percentToPass: 0,
          isQuestionRatingDisabled: false,
          pipelineStageId: categoryId,
          skills: custom
            ? []
            : selectedSkills.map(skill => ({
                id: skill.id,
                difficulty: selectedDifficulty,
              })),
        },
      });
      if (custom) {
        history.push(
          `/admin/${workspaceId}/openings/${jobOpeningId}/test/${createdTest.id}/questions/add`
        );
      } else {
        history.push(
          `/admin/${workspaceId}/openings/${jobOpeningId}/test/${createdTest.id}/questions`
        );
      }
    },
    [
      selectedSkills,
      createTestMutation,
      history,
      workspaceId,
      selectedDifficulty,
    ]
  );
  const handleTestCreation = useCallback(
    async ({ custom }: { custom: boolean }) => {
      if (custom && !canCreateCustomTest) {
        showPreviewFeatureModal.open('custom_test');
        return;
      }

      if (isTestsInJobOpeningLimitReached) {
        openUpsellModal('tests_in_job_opening_limit_reached');
        return;
      }

      amplitude?.logEvent('job templates/async interview create test', {
        skills: selectedSkills,
      });

      if (jobOpeningId && categoryId) {
        createTest({ jobOpeningId, categoryId, custom });
      } else {
        openAddTestToPipelineModal(
          (jobOpeningId, pipelineCategoryId) =>
            createTest({
              jobOpeningId,
              categoryId: pipelineCategoryId,
              custom,
            }),
          custom
        );
      }
    },
    [
      isTestsInJobOpeningLimitReached,
      canCreateCustomTest,
      jobOpeningId,
      categoryId,
      selectedSkills,
      showPreviewFeatureModal,
      openUpsellModal,
      createTest,
      openAddTestToPipelineModal,
    ]
  );

  const showEmptyState = useMemo(
    () =>
      !Object.values(filteredTabs as { [key: string]: ISkill[] }).some(
        item => item.length > 0
      ),
    [filteredTabs]
  );

  const hasHardQuestions = useMemo(
    () =>
      allTabs[activeTab]?.some(
        skill =>
          skill.questions?.video?.videoQuestion?.['hard'] ||
          skill.questions?.video?.freeText?.['hard']
      ) ?? false,
    [activeTab, allTabs]
  );

  if (!skillsQuery.isSuccess) {
    return null;
  }

  return (
    <section>
      <PaddedWrapper>
        <SearchBar
          placeholder="Search for skills"
          value={searchQuery}
          reset={() => {
            setSearchQuery('');
            setActiveTab(DEFAULT_CATEGORY_TAB);
          }}
          handleSearchChange={handleSearchChange}
          handleCreateCustomTest={() => handleTestCreation({ custom: true })}
        />
      </PaddedWrapper>
      <SkillTabs
        tabs={allTabsKeys}
        activeTab={activeTab}
        onTabClick={setActiveTab}
      />
      <PaddedWrapper>
        {!showEmptyState && (
          <>
            <DifficultySelector>
              <DSInlineField for="difficulty-level-standard" label="Standard">
                <DSRadio
                  name="difficultyLevel"
                  type="radio"
                  id="difficulty-level-standard"
                  checked={selectedDifficulty === 'standard'}
                  onChange={() => setSelectedDifficulty('standard')}
                />
              </DSInlineField>
              <DSInlineField
                for="difficulty-level-hard"
                label={
                  <>
                    Advanced
                    <StyledPlanTag />
                  </>
                }
                disabled={!hasHardQuestions}
              >
                <DSRadio
                  name="difficultyLevel"
                  type="radio"
                  id="difficulty-level-hard"
                  checked={selectedDifficulty === 'hard'}
                  disabled={!hasHardQuestions}
                  onChange={() => {
                    if (canAccessAdvancedSkills) {
                      setSelectedDifficulty('hard');
                    } else {
                      openUpsellModal('premium_feature');
                    }
                  }}
                />
              </DSInlineField>
            </DifficultySelector>
            <Bottom>
              {searchQuery === '' ? (
                <SkillGroup>
                  {activeTab
                    ? allTabs[activeTab].map(skill => {
                        const selected = selectedSkills.some(
                          selectedSkill => selectedSkill.id === skill.id
                        );
                        const skillQuestions = skill.questions?.video;
                        const isDisabled =
                          !skillQuestions?.videoQuestion?.[
                            selectedDifficulty
                          ] && !skillQuestions?.freeText?.[selectedDifficulty];
                        return (
                          <Skill
                            key={skill.id}
                            skill={skill}
                            disabled={isDisabled}
                            selected={selected}
                            onClick={handleSkillSelection}
                          />
                        );
                      })
                    : null}
                </SkillGroup>
              ) : (
                <SkillGroup withHeader>
                  {filteredTabsKeys.map(tabKey =>
                    filteredTabs[tabKey].length > 0 ? (
                      <li key={tabKey}>
                        <h4>{TAB_LABEL_DICTIONARY[tabKey] ?? tabKey}</h4>
                        <SkillGroup>
                          {filteredTabs[tabKey].map(skill => {
                            const selected = selectedSkills.some(
                              selectedSkill => selectedSkill.id === skill.id
                            );

                            return (
                              <Skill
                                key={skill.id}
                                skill={skill}
                                selected={selected}
                                onClick={handleSkillSelection}
                              />
                            );
                          })}
                        </SkillGroup>
                      </li>
                    ) : null
                  )}
                </SkillGroup>
              )}
              <SelectedSkills>
                <ButtonsContainer>
                  <DSButton
                    onClick={() => handleTestCreation({ custom: false })}
                    disabled={
                      !canCreateJobOpening || selectedSkills.length === 0
                    }
                    data-rh={
                      !canCreateJobOpening
                        ? 'Access restricted. Contact workspace admin to change your user rights.'
                        : null
                    }
                  >
                    {testOnly ? 'Create test' : 'Add test'}{' '}
                  </DSButton>
                </ButtonsContainer>
              </SelectedSkills>
            </Bottom>
          </>
        )}
      </PaddedWrapper>
      {showEmptyState && (
        <EmptyStateWrapper>
          <EmptyState
            createCustomTest={() => handleTestCreation({ custom: true })}
          />
        </EmptyStateWrapper>
      )}
    </section>
  );
}

const PaddedWrapper = styled.div`
  padding: 0 36px 0 36px;
`;

const StyledPlanTag = styled(PlanTag)`
  margin-left: 8px;
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;

  & > ul {
    align-content: flex-start;
    margin: 32px 0;
    min-height: 270px;
    width: 75%;
  }
`;

const DifficultySelector = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
`;

const EmptyStateWrapper = styled.div`
  padding-top: 24px;
  padding-bottom: 36px;
`;

const SelectedSkills = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
  padding-left: 16px;
  margin: 32px 0;

  h3 {
    font-size: 14px;
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: column;
    list-style: none;
  }
`;

const ButtonsContainer = styled.div`
  margin-top: auto;
  align-self: flex-end;

  & > button:first-of-type {
    margin-right: 8px;
  }
`;
