import React, { useMemo, useState } from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  DSButton,
  DSColumn,
  DSField,
  DSModal,
  DSRow,
} from '@hundred5/design-system';
import { Form, Formik } from 'formik';
import { useMedia } from 'react-use';
import * as yup from 'yup';

import { FormikSelectField, Icon, TId } from '@/features/common';
import { useBookmarkedJobOpenings } from '@/features/dashboard';
import {
  useJobOpeningPermission,
  useWorkspacePermission,
} from '@/features/permissions';
import { useJobOpeningsLimit } from '@/hooks/planLimits';
import { useUpsellModal } from '@/hooks/upsellModal';

import { useAddTestToPipelineModalContext } from '../../hooks';

import addTestToPipelineIlustration from './assets/add-test-to-pipeline-illustration.svg';
import {
  AddJobModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  StageSelector,
} from './ui';
import { AddNewButton } from './ui/add-new-job-button';
import { AddNewStageModal } from './ui/add-new-stage-modal';

const validationSchema = yup.object().shape({
  jobOpeningId: yup.string().required(),
  pipelineStageId: yup.string().required(),
});

export interface IAddTestToPipelineFormValues {
  jobOpeningId: TId;
  pipelineStageId: TId;
}
export const AddTestToPipelineModal = () => {
  const {
    close,
    isOpen,
    onAddTest,
    isCustomTest,
  } = useAddTestToPipelineModalContext();
  const { jobOpenings } = useBookmarkedJobOpenings({ filter: 'open' });
  const hasJobOpeningPermission = useJobOpeningPermission();
  const jobOpeningItems = useMemo(
    () =>
      jobOpenings
        .filter(jobOpening =>
          hasJobOpeningPermission('pipeline', 'update', {
            id: jobOpening.id,
            visibility: jobOpening.visibility,
          })
        )
        .map(jobOpening => ({
          id: jobOpening.id,
          label: jobOpening.name,
          labeltext: jobOpening.name,
        })),
    [jobOpenings, hasJobOpeningPermission]
  );
  const [isAddTestModalOpen, setIsAddTestModalOpen] = useState(false);
  const [isAddStageModalOpen, setIsAddStageModalOpen] = useState(false);
  const isMobile = useMedia('(max-width: 768px)');
  const isBelowJobOpeningsLimit = useJobOpeningsLimit();
  const hasPermissionToCreateJobOpening = useWorkspacePermission()(
    'job openings',
    'create'
  );
  const canCreateJobOpening =
    hasPermissionToCreateJobOpening && isBelowJobOpeningsLimit;
  const { openUpsellModal } = useUpsellModal();

  return (
    <>
      <DSModal
        open={isOpen}
        onClose={close}
        contentStyle={{ width: isMobile ? '100%' : '644px' }}
      >
        <ModalHeader>
          Add {isCustomTest ? `a custom` : 'this'} test to a pipeline stage
        </ModalHeader>
        <DSModal.CloseButton fixed small onClick={close} />

        <DSModal.Separator />

        <Formik<IAddTestToPipelineFormValues>
          initialValues={{
            jobOpeningId:
              jobOpeningItems.length === 1 ? jobOpeningItems[0]?.id ?? '' : '',
            pipelineStageId: '',
          }}
          onSubmit={async values => {
            onAddTest &&
              (await onAddTest(values.jobOpeningId, values.pipelineStageId));
            close();
          }}
          validationSchema={validationSchema}
          validateOnMount
        >
          {({ isValid, isSubmitting }) => (
            <Form>
              <ModalBody>
                <DSRow gap="12px" smWrap>
                  <DSColumn size={12} sm={24}>
                    <DSField for="jobOpening" required label="job">
                      <FormikSelectField
                        name="jobOpeningId"
                        items={jobOpeningItems}
                        placeholder="Select job"
                        Footer={
                          <AddNewButton
                            onClick={() =>
                              isBelowJobOpeningsLimit
                                ? setIsAddTestModalOpen(true)
                                : openUpsellModal('job_openings_limit_reached')
                            }
                            disabled={!!!canCreateJobOpening}
                            data-rh={
                              !hasPermissionToCreateJobOpening
                                ? 'Access restricted. Contact workspace admin to change your user rights.'
                                : null
                            }
                          >
                            <Icon icon={regular('plus')} />
                            Add new job
                          </AddNewButton>
                        }
                      />
                    </DSField>
                  </DSColumn>
                  <DSColumn size={12} sm={24}>
                    <DSField
                      for="pipelineStageId"
                      required
                      label="pipeline stage"
                    >
                      <StageSelector
                        onAddNewStage={() => setIsAddStageModalOpen(true)}
                      />
                    </DSField>
                  </DSColumn>
                </DSRow>

                <AddJobModal
                  open={isAddTestModalOpen}
                  onClose={() => setIsAddTestModalOpen(false)}
                />
                <AddNewStageModal
                  open={isAddStageModalOpen}
                  onClose={() => setIsAddStageModalOpen(false)}
                />
              </ModalBody>
              {!isMobile && <img alt="" src={addTestToPipelineIlustration} />}
              <ModalFooter>
                <DSButton type="submit" disabled={!isValid || isSubmitting}>
                  Add
                </DSButton>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </DSModal>
    </>
  );
};
