import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { LinkButton } from '@hundred5/design-system';
import { debounce, groupBy, sortBy, trim } from 'lodash';
import { useHistory, useParams } from 'react-router';

import { TId, useCustomTestCTA, usePageLayout } from '@/features/common';
import { useWorkspacePermission } from '@/features/permissions';
import { useJobOpeningTestsLimitQuery } from '@/features/plan-limits';
import { usePreviewFeatureModal } from '@/features/preview-feature/hooks';
import { useSkillsQuery } from '@/features/skills';
import { TestType } from '@/features/test/types';
import { usePlanLimits } from '@/hooks/planLimits';
import { useWorkspaceId } from '@/hooks/router';
import { useCreateCustomSkillsTest } from '@/hooks/tests';
import { useUpsellModal } from '@/hooks/upsellModal';
import { trackIntercomEvent } from '@/intercom';

import { useAddTestToPipelineModalContext, useTestOnly } from '../../hooks';
import { useTemplatesQuery, useTemplateTypesQuery } from '../../queries';
import { useTemplateCategoriesQuery } from '../../queries/categories';
import { ITemplate } from '../../types';
import { SearchBar } from '../test-library/ui';

import { Categories, LibraryLoader, SearchResults, TemplateType } from './ui';

type SkillTestLibraryProps = {
  headerClassName?: string;
};

export const SkillTestLibrary = ({
  headerClassName,
}: SkillTestLibraryProps) => {
  const workspaceId = useWorkspaceId();

  const history = useHistory();
  const { openUpsellModal } = useUpsellModal();
  const showPreviewFeatureModal = usePreviewFeatureModal();
  const canAccessFeature = usePlanLimits();
  const canCreateCustomTest = canAccessFeature('create_custom_test');
  const createCustomSkillsTest = useCreateCustomSkillsTest();
  const { createCustomTestCTA } = useCustomTestCTA();
  const canCreateJobOpening = useWorkspacePermission()(
    'job openings',
    'create'
  );
  const testOnly = useTestOnly();
  const {
    open: openAddTestToPipelineModal,
  } = useAddTestToPipelineModalContext();

  const { isTestsInJobOpeningLimitReached } = useJobOpeningTestsLimitQuery();
  const pageLayout = usePageLayout();

  const skillsQuery = useSkillsQuery({
    requestParams: { testType: TestType.Quiz },
  });
  const categoriesQuery = useTemplateCategoriesQuery();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const [categoryId, setCategoryId] = useState<TId | undefined>();
  const templatesQuery = useTemplatesQuery({ query: debouncedSearchQuery });
  const templateTypesQuery = useTemplateTypesQuery(
    { categoryId: categoryId! },
    { enabled: !!categoryId }
  );
  const { jobOpeningId, categoryId: pipelineCategoryId } = useParams<{
    jobOpeningId?: TId;
    categoryId?: TId;
  }>();

  const defaultCategoryId = useMemo(() => {
    if (categoriesQuery.data && categoriesQuery.data.length > 0) {
      return categoriesQuery.data[0].id;
    }
  }, [categoriesQuery.data]);

  const templatesByType = useMemo(
    () =>
      groupBy(
        sortBy(templatesQuery.data, ['orderWeight']),
        'positionType.name'
      ),
    [templatesQuery.data]
  );

  const debounceSearch = useMemo(
    () =>
      debounce((value?: string) => {
        setDebouncedSearchQuery(value || '');
        amplitude?.logEvent('job templates/search', {
          'search query': value,
        });
      }, 300),
    []
  );

  useEffect(() => {
    setCategoryId(defaultCategoryId);
  }, [defaultCategoryId]);

  useEffect(() => {
    if (searchQuery) {
      categoryId !== '' && setCategoryId('');
      debounceSearch(searchQuery);
    } else {
      categoryId === '' && setCategoryId(defaultCategoryId);
      setDebouncedSearchQuery('');
    }
  }, [
    searchQuery,
    categoryId,
    defaultCategoryId,
    debouncedSearchQuery,
    debounceSearch,
  ]);

  useEffect(() => {
    const trimedSearchQuery = trim(debouncedSearchQuery);
    trimedSearchQuery &&
      trackIntercomEvent('template_searched', {
        template: trimedSearchQuery,
      });
  }, [debouncedSearchQuery]);

  const handleCreateCustomTest = async (jobOpeningId, pipelineCategoryId) => {
    if (!canCreateCustomTest) {
      showPreviewFeatureModal.open('custom_test');
      return;
    }

    if (isTestsInJobOpeningLimitReached) {
      openUpsellModal('tests_in_job_opening_limit_reached');
      return;
    }
    if (!canCreateCustomTest) {
      showPreviewFeatureModal.open('custom_test');
      return;
    }
    await createCustomSkillsTest(jobOpeningId, pipelineCategoryId);
  };
  const onCreateCustomTest = () => {
    if (testOnly) {
      handleCreateCustomTest(jobOpeningId, pipelineCategoryId);
    } else {
      openAddTestToPipelineModal(handleCreateCustomTest, true);
    }
  };

  const handleTemplateSelect = useCallback(
    (template?: ITemplate) => {
      if (jobOpeningId && pipelineCategoryId) {
        history.push(
          `/admin/${workspaceId}/test-library/${jobOpeningId}/${pipelineCategoryId}/${template?.id}/`
        );
      } else {
        history.push(`/admin/${workspaceId}/test-library/${template?.id}/`);
      }
    },
    [workspaceId, history, jobOpeningId, pipelineCategoryId]
  );

  return (
    <>
      <Container>
        <Header className={headerClassName} offsetTop={pageLayout.offsetTop}>
          <SearchBar
            placeholder="Search by skill or job role"
            value={searchQuery}
            reset={() => {
              setSearchQuery('');
            }}
            handleSearchChange={event => {
              setSearchQuery(event.target.value);
            }}
            handleCreateCustomTest={() => {
              onCreateCustomTest();
              trackIntercomEvent('created_custom_test_template_gallery');
            }}
          />

          {categoriesQuery.isFetched && (
            <Categories
              categories={categoriesQuery.data!}
              selectedCategoryId={categoryId}
              onCategoryClick={cId => {
                setSearchQuery('');
                setCategoryId(cId);
              }}
            />
          )}
        </Header>

        {(!templatesQuery.isFetched || templateTypesQuery.isFetching) && (
          <LibraryLoader />
        )}

        {!(!templatesQuery.isFetched || templateTypesQuery.isFetching) && (
          <TemplateTypesContainer>
            {searchQuery && (
              <SearchResults
                templates={templatesQuery.data!}
                skills={skillsQuery.data}
                createCustomTest={onCreateCustomTest}
                onTemplateSelected={handleTemplateSelect}
              />
            )}

            {!searchQuery &&
              templateTypesQuery.data?.map(templateType => (
                <TemplateType
                  key={templateType.id}
                  templateTypeName={templateType.name}
                  templatesByType={templatesByType}
                  skills={skillsQuery.data}
                  createCustomTest={onCreateCustomTest}
                  onTemplateSelected={handleTemplateSelect}
                />
              ))}
          </TemplateTypesContainer>
        )}

        {canCreateJobOpening &&
          templatesQuery.isFetched &&
          templateTypesQuery.isFetched && (
            <Footer>
              <FooterText>Got something else in mind? </FooterText>
              <FooterButton
                variant="primary"
                onClick={() => {
                  onCreateCustomTest();
                  amplitude?.logEvent('job templates/click footer new test');
                }}
              >
                {createCustomTestCTA}
              </FooterButton>
            </Footer>
          )}
      </Container>
    </>
  );
};

const Container = styled.div`
  padding-bottom: 36px;
  @media all and (max-width: 768px) {
    padding-bottom: 24px;
  }
`;

const Header = styled.div<{ offsetTop: number }>`
  position: sticky;
  top: ${props => `${props.offsetTop}px`};
  z-index: 4;
  background: ${props => props.theme.colors.white};
  padding: 0 36px;

  @media all and (max-width: 768px) {
    padding: 0 16px;
    top: 60px;
  }
`;
const TemplateTypesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media all and (max-width: 768px) {
    padding: 24px 16px 0 16px;
    font-size: 14px;
  }
`;

const Footer = styled.div`
  padding: 24px 36px 36px 36px;
  bottom: 24px;
  left: 24px;
  @media all and (max-width: 768px) {
    display: none;
  }
`;

const FooterText = styled.div`
  display: inline;
  @media all and (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

const FooterButton = styled(LinkButton)`
  font-size: 14px;
  text-decoration: underline;
  color: ${props => props.theme.typography.colorPrimary};
`;
