import React, { ComponentProps } from 'react';
import { DSNotification as DesignSystemNotification } from '@hundred5/design-system';
import { format } from 'date-fns/format';
import { Link } from 'react-router-dom';

import { TFlashMessageContent } from '@/features/flash-messages';
import { useHistory } from '@/hooks/router';
import { trackIntercomEvent } from '@/intercom';

interface FlashMessageContentProps {
  flashMessage: TFlashMessageContent;
  onClose: () => void;
}

export const FlashMessageContent = ({
  flashMessage,
  onClose,
}: FlashMessageContentProps) => {
  const history = useHistory();
  const supportEmail = import.meta.env.VITE_SUPPORT_EMAIL;

  switch (flashMessage.type) {
    case 'saved':
      return (
        <Notification
          type="success"
          title="Changes saved!"
          autoClose={true}
          onClose={onClose}
        />
      );

    case 'candidate_unlocked':
      return (
        <Notification
          type="success"
          title="Candidate unlocked!"
          autoClose={true}
          onClose={onClose}
        />
      );

    case 'candidate_added':
      return (
        <Notification
          type="success"
          title="Candidate added!"
          autoClose={true}
          onClose={onClose}
        />
      );

    case 'candidate_rejected':
      return (
        <Notification
          type="success"
          title="Candidate rejected!"
          autoClose={true}
          onClose={onClose}
        />
      );

    case 'candidate_restored':
      return (
        <Notification
          type="success"
          title="Candidate restored!"
          autoClose={true}
          onClose={onClose}
        />
      );

    case 'feedback_thank_you':
      return (
        <Notification
          type="success"
          title="Thank you for your feedback!"
          autoClose={true}
          onClose={onClose}
        />
      );

    case 'invite_sent':
      return (
        <Notification
          type="success"
          title="Success"
          content="Invitation email has been sent!"
          autoClose={true}
          onClose={onClose}
        />
      );

    case 'email_sent':
      return (
        <Notification
          type="success"
          title={flashMessage.sendAt ? 'Emails scheduled' : 'Success'}
          content={
            flashMessage.sendAt
              ? `The email is scheduled to be sent on ${format(
                  flashMessage.sendAt,
                  'EEEE dd MMM yyyy [at] hh:mm aa'
                )}. You can review it in the candidate history.`
              : 'Email has been sent!'
          }
          autoClose={true}
          onClose={onClose}
        />
      );

    case 'email_limit_exceeded':
      return (
        <Notification
          type="failure"
          title="Limit exceeded"
          content="You have exceeded your daily email quota."
          onClose={onClose}
        />
      );

    case 'verification_email_sent':
      return (
        <Notification
          type="success"
          title="Success"
          content="Verification email has been sent!"
          autoClose={true}
          onClose={onClose}
        />
      );

    case 'attachment_uploaded':
      return (
        <Notification
          type="success"
          title="Success"
          content="File has been successfully uploaded!"
          autoClose={true}
          onClose={onClose}
        />
      );

    case 'email_verification': {
      const { error } = flashMessage;

      if (error === 'already_verified') {
        return (
          <Notification
            type="failure"
            title="Already verified"
            content={
              <>
                Your email address has already been verified. If you can't log
                in please contact us at{' '}
                <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
              </>
            }
            onClose={onClose}
          />
        );
      } else if (error === 'invalid_token') {
        return (
          <Notification
            type="failure"
            title="Invalid confirmation link"
            content="Confirmation link you supplied is invalid or expired."
            onClose={onClose}
          />
        );
      } else {
        return (
          <Notification
            type="success"
            title="Email verified"
            content="Your email address has been successfully verified."
            autoClose={true}
            onClose={onClose}
          />
        );
      }
    }

    case 'link_copied':
      return (
        <Notification
          type="success"
          title="Link copied to clipboard"
          content={
            flashMessage.meta && (
              <a href={flashMessage.meta.url}>{flashMessage.meta.url}</a>
            )
          }
          autoClose={true}
          onClose={onClose}
        />
      );

    case 'position_request_sent':
      return (
        <Notification
          type="success"
          title="Success"
          content="Your request for a new position has been sent!"
          autoClose={true}
          onClose={onClose}
        />
      );

    case 'server_error':
      const errorId = flashMessage.error.errorId;

      return (
        <Notification
          type="failure"
          title="Server error"
          content={
            <React.Fragment>
              We’ve been notified, but if the problem continues, please contact
              us either in chat or at{' '}
              <a
                href={`mailto:${supportEmail}${
                  errorId ? `?subject=Error ID: ${errorId}` : ''
                }`}
              >
                {supportEmail}
              </a>{' '}
              and have the following error ID ready:
              <br />
              <code>Error ID: {errorId}</code>
            </React.Fragment>
          }
          onClose={onClose}
        />
      );

    case 'network_error':
      return (
        <Notification
          type="failure"
          title="Network error"
          content="There's a network disconnect. Try checking your network connection."
          onClose={onClose}
        />
      );

    case 'client_error':
      const { error } = flashMessage;

      return (
        <Notification
          type="failure"
          title="Client error"
          content={
            <React.Fragment>
              We can't determine the cause of error. Please contact us at{' '}
              <a
                href={`mailto:${supportEmail}${
                  error.errorId ? `?subject=Error ID: ${error.errorId}` : ''
                }`}
              >
                {supportEmail}
              </a>{' '}
              and have the following error ID ready:
              <br />
              <code>Error ID: {error.errorId}</code>
              <br />
              <code>
                {error.customerMessage || error.message || error.type}
              </code>
            </React.Fragment>
          }
          onClose={onClose}
        />
      );

    case 'multi_client_error':
      const multiError = flashMessage.error;
      const maxErrorsToShow = 3;
      const remainingErrors = multiError.errors.length - maxErrorsToShow;
      const requestId = multiError.response?.headers['th-request-id'];

      return (
        <Notification
          type="failure"
          title="Multi client error"
          content={
            <>
              We can't determine the cause of error. Please contact us at{' '}
              {
                <a
                  href={`mailto:${supportEmail}${
                    requestId ? `?subject=Error ID: ${requestId}` : ''
                  }`}
                >
                  {supportEmail}
                </a>
              }{' '}
              and have the following error ID ready: <code>{requestId}</code>
              <ul>
                {multiError.errors
                  .slice(0, maxErrorsToShow)
                  .map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                {remainingErrors > 0 && <li>...and {remainingErrors} more</li>}
              </ul>
            </>
          }
          onClose={onClose}
        />
      );

    case 'password_reset_error':
      return (
        <Notification
          type="failure"
          title="Email not found"
          content={
            <p>
              Looks like that email does not exist. Try using a different email.
            </p>
          }
          onClose={onClose}
          actionLabel="Sign up"
          onActionClick={() => {
            history.push('/admin/signup');
            onClose();
          }}
        />
      );

    case 'image_upload_error': {
      if (flashMessage.error === 'unsupported_file_format') {
        return (
          <Notification
            type="failure"
            title="Unsupported format"
            content="Oops! We don't support that image format. Make sure you're using JPEG or PNG."
            onClose={onClose}
          />
        );
      } else if (flashMessage.error === 'file_too_large') {
        return (
          <Notification
            type="failure"
            title="Unsupported file size"
            content="Oops! The image size is too large. Make sure it’s less than 5 MB—try resizing it."
            onClose={onClose}
          />
        );
      } else {
        return null;
      }
    }

    case 'job_opening_load_error':
      return (
        <Notification
          type="failure"
          title="Not found"
          content="Uh oh! Looks like that job opening no longer exists. Try making a new one!"
          onClose={onClose}
        />
      );

    case 'test_load_error':
      return (
        <Notification
          type="failure"
          title="Not found"
          content="Uh oh! Looks like that test no longer exists. Try making a new one!"
          onClose={onClose}
        />
      );

    case 'test_generator_error':
      return (
        <Notification
          type="failure"
          title="Cannot generate a test"
          content="Ah, we need more skills! Try adding another skill section to make sure there's enough."
          onClose={onClose}
        />
      );

    case 'password_update_error':
      return (
        <Notification
          type="failure"
          title="Error"
          content="Sorry, your previous password is incorrect. Try sending a password reset email if you can't remember it."
          onClose={onClose}
          actionLabel="Reset password"
          onActionClick={() => {
            history.push('/admin/forgotten-password');
            onClose();
          }}
        />
      );

    case 'email_client_error': {
      const { error, meta } = flashMessage;

      if (error === 'invalid_extension') {
        return (
          <Notification
            type="failure"
            title="Unsupported file type"
            content={`${meta.fileName} is not supported. Please use a CSV file.`}
            onClose={onClose}
          />
        );
      } else if (error === 'invalid_format') {
        const { fileName, errorLines } = meta;

        return (
          <Notification
            type="failure"
            title="Invalid email format"
            content={`${fileName} contains invalid email addresses on line${
              errorLines!.length > 1 ? 's:' : ''
            } ${errorLines!
              .slice()
              .join(
                ', '
              )}. Please correct the email addresses and upload the file again.`}
            onClose={onClose}
          />
        );
      } else if (flashMessage.error === 'limit_exceeded') {
        return (
          <Notification
            type="failure"
            title="Limit exceeded"
            content={`You can only upload 50 emails at once. If you want to send invitations to more candidates, please split your address list into batches of 50 email addresses.`}
            onClose={onClose}
          />
        );
      } else {
        return null;
      }
    }

    case 'candidate_contact_info_email_set_to_original': {
      return (
        <Notification
          type="warning"
          title="Reverted"
          content={`Email has been reverted to the original value provided by the candidate at the time of application.`}
          onClose={onClose}
        />
      );
    }

    case 'candidate_contact_info_github_set_to_original': {
      return (
        <Notification
          type="warning"
          title="Reverted"
          content={`GitHub URL has been reverted to the original value provided by the candidate at the time of application.`}
          onClose={onClose}
        />
      );
    }

    case 'candidate_contact_info_linkedin_set_to_original': {
      return (
        <Notification
          type="warning"
          title="Reverted"
          content={`LinkedIn URL has been reverted to the original value provided by the candidate at the time of application.`}
          onClose={onClose}
        />
      );
    }

    case 'candidate_contact_info_name_set_to_original': {
      return (
        <Notification
          type="warning"
          title="Reverted"
          content={`Name has been reverted to the original value provided by the candidate at the time of application because it is a mandatory field and can’t be empty.`}
          onClose={onClose}
        />
      );
    }

    case 'candidate_contact_info_first_name_set_to_original': {
      return (
        <Notification
          type="warning"
          title="Reverted"
          content={`First name has been reverted to the original value provided by the candidate at the time of application because it is a mandatory field and can’t be empty.`}
          onClose={onClose}
        />
      );
    }

    case 'candidate_contact_info_last_name_set_to_original': {
      return (
        <Notification
          type="warning"
          title="Reverted"
          content={`Last name has been reverted to the original value provided by the candidate at the time of application because it is a mandatory field and can’t be empty.`}
          onClose={onClose}
        />
      );
    }

    case 'attachment_upload_error': {
      const { error, meta } = flashMessage;

      if (error === 'unsupported_file_format') {
        return (
          <Notification
            type="failure"
            title="Unsupported file type"
            content={`This file format is not supported. Please use one of the fallowing file formats: doc, docx, txt, pdf, jpg, jpeg, png.`}
            onClose={onClose}
          />
        );
      } else if (error === 'file_too_large') {
        return (
          <Notification
            type="failure"
            title="File too large"
            content={`The file size is ${
              !meta?.maxSizeMB
                ? 'too large'
                : `limited to ${meta?.maxSizeMB} MB`
            }, please check the size of the file you are trying to upload and try again.`}
            onClose={onClose}
          />
        );
      } else {
        return null;
      }
    }
    case 'third_party_login_error': {
      const { error, meta } = flashMessage;
      const providerNames = {
        linkedin: 'LinkedIn',
      };

      if (error === 'RequestTimeout') {
        return (
          <Notification
            type="failure"
            title="Something went wrong"
            content={
              <p>
                Sorry, logging in took longer than we expected. Please try
                again, or contact us at{' '}
                <a href={`mailto:${supportEmail}`}>{supportEmail}</a> if the
                problem continues.
              </p>
            }
            onClose={onClose}
          />
        );
      } else if (error === 'EmailNotProvided') {
        return (
          <Notification
            type="failure"
            title="Email missing"
            content={
              <p>
                Please add a verified email address to your{' '}
                {providerNames[meta.provider]} account and try again.
              </p>
            }
            onClose={onClose}
          />
        );
      } else if (error === 'UserNotFound') {
        return (
          <Notification
            type="failure"
            title="User Not Found"
            content={
              <p>
                There is no user associated with this{' '}
                {providerNames[meta.provider]} account. Please{' '}
                <Link to="/admin/signup">create an account</Link> first.
              </p>
            }
            onClose={onClose}
          />
        );
      } else if (error === 'UserNotUsingProvider') {
        return (
          <Notification
            type="failure"
            title="User Already Exists"
            content={
              <p>
                You have previously signed up using an email address and
                password. Please <Link to="/admin">Log In</Link>.
              </p>
            }
            onClose={onClose}
          />
        );
      } else {
        return (
          <Notification
            type="failure"
            title="Something went wrong"
            content={
              <p>
                Sorry, something went wrong. Please try again, or contact us at{' '}
                <a href={`mailto:${supportEmail}`}>{supportEmail}</a> if the
                problem continues.
              </p>
            }
            onClose={onClose}
          />
        );
      }
    }

    case 'restricted_tag_error':
      return (
        <Notification
          type="failure"
          title="Reserved tag"
          content={
            <p>
              <i>
                <b>{flashMessage.meta.tag}</b>
              </i>{' '}
              is a reserved system tag, please use another wording for your tag.
            </p>
          }
          onClose={onClose}
        />
      );

    case 'tag_length_error':
      return (
        <Notification
          type="failure"
          title="Tag character limit"
          content={
            <p>
              Tag length is limited to <b>{flashMessage.meta.maxLength}</b>{' '}
              characters.
            </p>
          }
          onClose={onClose}
        />
      );

    case 'test_invitation_resend_limit_reached':
      return (
        <Notification
          type="failure"
          title="Limit reached"
          content={`You have reached the limit on test invitation email resending. You can only resend the invitation 5 times. Try following up with the candidate directly.`}
          onClose={onClose}
        />
      );

    case 'subscription_paused':
      return (
        <Notification
          type="success"
          title="Subscription paused"
          content={`Your subscription has been paused successfully.`}
          autoClose={true}
          onClose={onClose}
        />
      );

    case 'email_template_action_success':
      const { action } = flashMessage;

      const getActionLabel = () => {
        switch (action) {
          case 'save':
            return 'saved';
          case 'update':
            return 'updated';
          case 'delete':
            return 'deleted';
          case 'archive':
            return 'archived';
        }
      };

      return (
        <Notification
          type="success"
          title="Success"
          content={`Email template ${getActionLabel()}!`}
          autoClose={true}
          onClose={onClose}
        />
      );

    case 'email_template_preview_success':
      return (
        <Notification
          type="success"
          title="Success"
          content={`Test template is sent to your email address!`}
          autoClose={true}
          onClose={onClose}
        />
      );

    case 'email_bad_template_error':
      return (
        <Notification
          type="failure"
          title="Email sending failed"
          content={`Error: ${flashMessage.error ||
            'please check if the variables used in the email template are defined in the variable dropdown menu.'}`}
          onClose={onClose}
        />
      );

    case 'email_template_variable_error':
      return (
        <Notification
          type="failure"
          title="Variable validation error"
          content={`The ${flashMessage.variable} variable is not valid, try selecting one of the available variables from the dropdown menu.`}
          onClose={onClose}
        />
      );

    case 'email_sending_failed_error':
      return (
        <Notification
          type="failure"
          title="Email sending failed"
          content={flashMessage.error}
          onClose={onClose}
        />
      );

    case 'coupon_limit_reached':
      return (
        <Notification
          type="failure"
          title="Invalid promo code"
          content={'Coupon has reached max redemptions for this account'}
          onClose={onClose}
        />
      );

    case 'detect_ai_answer_and_add_note':
      return (
        <Notification
          type="success"
          title="Success"
          content="AI detection has been completed and the result has been added to the answer's notes."
          autoClose={true}
          onClose={onClose}
        />
      );

    case 'evaluate_ai_answer_and_add_note':
      return (
        <Notification
          type="success"
          title="Success"
          content="AI evaluation has been completed and the result has been added to the answer's notes."
          autoClose={true}
          onClose={onClose}
        />
      );

    case 'recaptcha_error':
      return (
        <Notification
          type="failure"
          title="reCaptcha error"
          content="We couldn't connect with reCaptcha service. Try checking your network connection."
          onClose={onClose}
        />
      );

    case 'character_limit_reached':
      return (
        <Notification
          type="failure"
          title="Character limit reached"
          content="Oops! It seems like you've hit the character limit. Please try shortening the page content!"
          onClose={onClose}
        />
      );

    case 'gmail_user_data_deleted':
      return (
        <Notification
          type="success"
          title="Data deletion completed!"
          autoClose={true}
          onClose={onClose}
        />
      );

    case 'gmail_email_deleted':
      return (
        <Notification
          type="success"
          title="Email deleted!"
          autoClose={true}
          onClose={onClose}
        />
      );

    case 'gmail_synced':
      return (
        <Notification
          type="success"
          title="Import completed!"
          autoClose={true}
          onClose={onClose}
        />
      );

    case 'verification_email_error':
      return (
        <Notification
          type="failure"
          title="Verification process failed"
          content={
            <>
              {flashMessage.errorDescription}
              <br />
              <br />
              If you have any questions, please contact us at{' '}
              <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
            </>
          }
          onClose={onClose}
        />
      );

    default:
      return null;
  }
};

const Notification = (
  props: ComponentProps<typeof DesignSystemNotification>
) => {
  props.type === 'failure' &&
    trackIntercomEvent('error_popup_recieved', {
      title: props.title,
      content: props.content,
    });
  return <DesignSystemNotification {...props} />;
};
