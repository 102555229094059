import React from 'react';
import { uuid4 } from '@sentry/utils';
import { SplitFactory } from '@splitsoftware/splitio-react';
import store from 'store';

interface Props {
  children: React.ReactNode;
}
const USER_ID_KEY = 'anonymous_user_id';
const getUserIdFromStore = (): string => {
  return store.get(USER_ID_KEY);
};
const setUserIdToStore = (uuid: string) => {
  store.set(USER_ID_KEY, uuid);
};

const AnonymousSplit: React.FC<Props> = props => {
  const getUserId = () => {
    let userId: string | undefined = getUserIdFromStore();
    if (!userId) {
      const uuid = uuid4();
      setUserIdToStore(uuid);
      userId = uuid;
    }
    return userId;
  };

  const splitConfig: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: import.meta.env.VITE_SPLIT_API_KEY || 'localhost',
      trafficType: 'anonymous',
      key: getUserId(),
    },
    startup: {
      readyTimeout: 1,
    },
    storage: {
      type: 'LOCALSTORAGE',
    },
  };

  return (
    <SplitFactory config={splitConfig} updateOnSdkReady updateOnSdkTimedout>
      {attrs =>
        attrs.isReady || attrs.isTimedout ? <>{props.children}</> : null
      }
    </SplitFactory>
  );
};

export default AnonymousSplit;
