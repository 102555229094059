import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';

import api from '@/api/api';
import {
  AccountsClientError,
  AuthorizationError,
  ClientError,
  MultiClientError,
  NetworkError,
  ReCaptchaError,
  ServerError,
} from '@/errors';
import { getSharedAuthEnabled, useLogout } from '@/features/toggl-accounts';
import togglAccountsApi from '@/features/toggl-accounts/api/toggl-accounts-api';

const AxiosInterceptorsHandler = ({ children }) => {
  const logout = useLogout({ returnTo: window.location.href });
  const sharedAuthEnabled = getSharedAuthEnabled();

  useEffect(() => {
    const responseInterceptor = api.interceptors.response.use(
      response => {
        if (response.status === 207) {
          const error = new MultiClientError(response);
          Sentry.captureException(error);

          return Promise.reject(error);
        } else {
          return response;
        }
      },
      (error: AxiosError) => {
        if (error.response) {
          const { response } = error;
          if (response.status >= 500) {
            return Promise.reject(new ServerError(response));
          } else if (response.status === 401) {
            if (sharedAuthEnabled) {
              logout();
            }
            return Promise.reject(new AuthorizationError());
          } else {
            const clientErr = new ClientError(response);
            Sentry.captureException(clientErr, {
              extra: {
                type: clientErr.type,
                errorId: clientErr.errorId,
              },
            });

            return Promise.reject(clientErr);
          }
        } else if (error instanceof ReCaptchaError) {
          Sentry.captureException(error);
          return Promise.reject(error);
        } else {
          return Promise.reject(new NetworkError());
        }
      }
    );

    const accountsResponseInterceptor = togglAccountsApi.interceptors.response.use(
      response => {
        if (response.status === 207) {
          return Promise.reject(new MultiClientError(response));
        } else {
          return response;
        }
      },
      (error: AxiosError) => {
        if (error.response) {
          const { response } = error;
          if (response.status >= 500) {
            return Promise.reject(new ServerError(response));
          } else if (response.status === 401) {
            if (!error.config?.skipLogoutOnUnauthorized) {
              logout();
            }

            return Promise.reject(new AuthorizationError());
          } else {
            const clientErr = new AccountsClientError(response);
            Sentry.captureException(clientErr, {
              extra: {
                error: clientErr.error,
                errorDescription: clientErr.errorDescription,
              },
            });

            return Promise.reject(clientErr);
          }
        } else if (error instanceof ReCaptchaError) {
          Sentry.captureException(error);
          return Promise.reject(error);
        } else {
          return Promise.reject(new NetworkError());
        }
      }
    );

    return () => {
      api.interceptors.response.eject(responseInterceptor);
      togglAccountsApi.interceptors.response.eject(accountsResponseInterceptor);
    };
  }, [logout, sharedAuthEnabled]);

  return children;
};

export default AxiosInterceptorsHandler;
