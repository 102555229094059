import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { DSButton, DSSearchInput } from '@hundred5/design-system';

import { useCustomTestCTA } from '@/features/common';
import { useWorkspacePermission } from '@/features/permissions';

interface SearchBarProps {
  placeholder: string;
  value: string;
  reset: () => void;
  handleSearchChange: (event: any) => void;
  handleSearchBlur?: () => void;
  handleCreateCustomTest: (event: any) => void;
}

export function SearchBar({
  placeholder,
  value,
  reset,
  handleSearchChange,
  handleCreateCustomTest,
  handleSearchBlur,
}: SearchBarProps) {
  const { createCustomTestCTA } = useCustomTestCTA();
  const canCreateJobOpening = useWorkspacePermission()(
    'job openings',
    'create'
  );

  return (
    <Top>
      <SearchInputContainer>
        <DSSearchInput
          placeholder={placeholder}
          value={value}
          showResetButton
          reset={reset}
          onChange={handleSearchChange}
          onBlur={handleSearchBlur}
        />
      </SearchInputContainer>
      <CreateCustomTestButton
        variant={'tertiary'}
        onClick={event => {
          handleCreateCustomTest && handleCreateCustomTest(event);
          amplitude?.logEvent('job templates/click top new test');
        }}
        disabled={!canCreateJobOpening}
        data-rh={
          !canCreateJobOpening
            ? 'Access restricted. Contact workspace admin to change your user rights.'
            : null
        }
      >
        {createCustomTestCTA}
      </CreateCustomTestButton>
    </Top>
  );
}

const Top = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  padding-top: 24px;
  margin-bottom: 34px;

  @media all and (max-width: 768px) {
    margin-bottom: 24px;
    width: 100%;
  }
`;

const SearchInputContainer = styled.div`
  width: 670px;
`;

const CreateCustomTestButton = styled(DSButton)`
  @media all and (max-width: 768px) {
    display: none;
  }
`;
