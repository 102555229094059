import React, { useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  DSButton,
  DSCheckbox,
  DSInlineField,
  DSRadio,
} from '@hundred5/design-system';
import TextareaAutosize from 'react-textarea-autosize';

import { Icon, TId } from '@/features/common';

interface QuestionAnswerOptionProps {
  id: TId;
  type: 'single-choice' | 'multiple-choice';
  value: string;
  isCorrect: boolean;
  onValueChange: (value: string) => void;
  onOptionRemove: () => void;
  onOptionSelect: (checked: boolean) => void;
}

export const QuestionAnswerOption = ({
  id,
  type,
  value,
  isCorrect,
  onValueChange,
  onOptionRemove,
  onOptionSelect,
}: QuestionAnswerOptionProps) => {
  const [editing, setEditing] = useState(false);

  const handleEditEnd = (newValue: string) => {
    if (!newValue) {
      onOptionRemove();
    } else if (newValue !== value) {
      onValueChange(newValue);
    }
    setEditing(false);
  };

  return (
    <OptionContainer>
      <Wrapper>
        <Icon icon={regular('grip-dots-vertical')} color="purple-60" />
      </Wrapper>

      <InlineField
        for={`option-${id}`}
        label={
          editing ? (
            <TextArea
              id={`option-${id}`}
              autoFocus={true}
              defaultValue={value}
              onBlur={event => {
                const newValue = event.target.value.trim();
                handleEditEnd(newValue);
              }}
              onKeyDown={event => {
                if (event.key === 'Enter' && !event.shiftKey) {
                  event.preventDefault();
                  const target = event.target as HTMLTextAreaElement;
                  const newValue = target.value.trim();
                  handleEditEnd(newValue);
                }
              }}
            />
          ) : (
            <OptionValue id={`option-${id}`} onClick={() => setEditing(true)}>
              {value}
            </OptionValue>
          )
        }
      >
        {type === 'single-choice' ? (
          <DSRadio
            name="single-choice-options"
            value={value}
            checked={isCorrect}
            onChange={event => onOptionSelect(event.target.checked)}
          />
        ) : (
          <DSCheckbox
            name="multiple-choice-options"
            value={value}
            checked={isCorrect}
            onChange={event => onOptionSelect(event.target.checked)}
          />
        )}
      </InlineField>

      <DSButton
        variant="tertiary"
        size="xsmall"
        onClick={onOptionRemove}
        Icon={<Icon icon={regular('trash')} color="purple-100" />}
      />
    </OptionContainer>
  );
};

const OptionContainer = styled.div`
  display: grid;
  grid-template-columns: 10px minmax(0, 1fr) min-content;
  gap: 8px;
`;

const Wrapper = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InlineField = styled(DSInlineField)`
  margin-top: 2px;

  label {
    width: 100%;
  }
`;

const TextArea = styled(TextareaAutosize)`
  display: block;
  width: 100%;
  background: transparent;
  -webkit-appearance: none;
  resize: none;
  border: none;
  padding: 0;
  margin: 0;
  line-height: 21px;
  font-size: 14px;
  color: ${props => props.theme.typography.colorPrimary};

  &:focus {
    outline: 0;
  }
`;

const OptionValue = styled.div`
  white-space: pre-wrap;
  font-size: 14px;
  color: ${props => props.theme.typography.colorPrimary};
  word-break: break-word;
`;
