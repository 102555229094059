import * as React from 'react';
import styled from '@emotion/styled';
import { useMedia } from 'react-use';

import { useWorkspaceTreatments } from '@/components/splits';
import { PageLayout } from '@/features/common';
import { EmailDisconnectedBanner, useMeQuery } from '@/features/me';
import { Navigation } from '@/features/navigation';
import { TopUpsellBanner } from '@/features/upsell';

import { usePromotePremium } from './admin-page.hooks';

interface AdminPageProps {
  showNavigationOnMobile?: boolean;
  children?: React.ReactNode;
}

const SINGLE_BANNER_OFFSET = 36;
const DOUBLE_BANNER_OFFSET = 72;

export const AdminPage = ({
  children,
  showNavigationOnMobile,
}: AdminPageProps) => {
  const isMobile = useMedia('(max-width: 768px)');
  const { workspaceId, promotePremium } = usePromotePremium();
  const { data: meQuery } = useMeQuery();

  const { fe_admin_app_emails_tab } = useWorkspaceTreatments([
    'fe_admin_app_emails_tab',
  ]);
  const isEmailIntegrationVisible = fe_admin_app_emails_tab.treatment === 'on';

  const showUpsellBanner = promotePremium && !isMobile && workspaceId;
  const showEmailDisconnectedBanner =
    isEmailIntegrationVisible && meQuery?.gmailStatus === 'disconnected';

  const offset =
    showUpsellBanner && showEmailDisconnectedBanner
      ? DOUBLE_BANNER_OFFSET
      : showUpsellBanner || showEmailDisconnectedBanner
      ? SINGLE_BANNER_OFFSET
      : 0;

  return (
    <PageLayout offsetTop={offset}>
      <AdminPageContainer paddingTop={offset}>
        <NavBar>
          {showUpsellBanner ? (
            <TopUpsellBanner workspaceId={workspaceId} />
          ) : null}
          {showEmailDisconnectedBanner ? <EmailDisconnectedBanner /> : null}
        </NavBar>
        {isMobile && !showNavigationOnMobile ? (
          <AdminPageContent>
            <React.Suspense fallback={null}>{children}</React.Suspense>
          </AdminPageContent>
        ) : (
          <Navigation>
            <AdminPageContent>
              <React.Suspense fallback={null}>{children}</React.Suspense>
            </AdminPageContent>
          </Navigation>
        )}
      </AdminPageContainer>
    </PageLayout>
  );
};

const AdminPageContainer = styled.div<{ paddingTop: number }>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: ${props => `${props.paddingTop}px`};
`;

const NavBar = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10000;
`;

const AdminPageContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;
